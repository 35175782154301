import { useMemo } from 'react'

interface Endpoint {
  rest: string
  graphql: string
}

let endpoint: Endpoint

export function initializeEndpoint (): Endpoint {
  const rest = process.env.NODE_ENV !== 'production' ? 'http://localhost:30001' : 'https://www.jdjeon.com/v1.0'
  const graphql = `${rest}/graphql`
  return { rest, graphql }
}

export function useEndpoint (): Endpoint {
  return useMemo(initializeEndpoint, [endpoint])
}
