import { useEffect } from 'react'

import Router from 'next/router'
import type { AppProps } from 'next/app'

function saveScrollPos (url: string): void {
  const scrollPos = { x: window.scrollX, y: window.scrollY }
  sessionStorage.setItem(url, JSON.stringify(scrollPos))
}

function restoreScrollPos (url: string): void {
  const item = sessionStorage.getItem(url)
  if (item == null) return

  const scrollPos = JSON.parse(item)
  if (scrollPos == null) return

  window.scrollTo(scrollPos.x, scrollPos.y)
}

export default function useScrollRestoration (router: AppProps['router']): void {
  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      let shouldScrollRestore = false
      window.history.scrollRestoration = 'manual'
      restoreScrollPos(router.asPath)

      const onBeforeUnload = (event: BeforeUnloadEvent): void => {
        saveScrollPos(router.asPath)
        delete event.returnValue
      }

      const onRouteChangeStart = (): void => {
        saveScrollPos(router.asPath)
      }

      const onRouteChangeComplete = (url: string): void => {
        if (shouldScrollRestore) {
          shouldScrollRestore = false
          restoreScrollPos(url)
        }
      }

      window.addEventListener('beforeunload', onBeforeUnload)
      Router.events.on('routeChangeStart', onRouteChangeStart)
      Router.events.on('routeChangeComplete', onRouteChangeComplete)
      Router.beforePopState(() => {
        shouldScrollRestore = true
        return true
      })

      return () => {
        window.removeEventListener('beforeunload', onBeforeUnload)
        Router.events.off('routeChangeStart', onRouteChangeStart)
        Router.events.off('routeChangeComplete', onRouteChangeComplete)
        Router.beforePopState(() => true)
      }
    }
  }, [router])
}
