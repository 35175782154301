import type { AppContext, AppProps } from 'next/app'
import { initializeApollo, useApollo } from '@/graphql/apollo-client'
import { ApolloProvider } from '@apollo/client'
import App from 'next/app'
import { useDeviceSelectors } from 'react-device-detect'
import { UserRoleDocument, UserRoleQuery, UserRoleQueryVariables } from '@/graphql/queries/user-role.generated'
import Router from 'next/router'
import { NextPage } from 'next'
import { ReactElement, ReactNode } from 'react'
import { Device } from '@/components/layout'
import useScrollRestoration from '@/hooks/use-scroll-restoration'

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement, options?: {device: Device}) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function MyApp ({ Component, pageProps, router }: AppPropsWithLayout): JSX.Element {
  useScrollRestoration(router)
  const apolloClient = useApollo(pageProps)
  const [selectors] = useDeviceSelectors(pageProps.userAgent)

  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <ApolloProvider client={apolloClient}>
      {getLayout(<Component {...pageProps} />, { device: selectors })}
    </ApolloProvider>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  const userAgent = appContext.ctx.req?.headers['user-agent'] ?? navigator.userAgent

  const { route } = appContext.router

  if (route.startsWith('/admin')) {
    const apolloClient = initializeApollo(null, appContext.ctx)
    const response = await apolloClient.query<UserRoleQuery, UserRoleQueryVariables>({
      query: UserRoleDocument,
      variables: {
        id: null
      },
      errorPolicy: 'all'
    })

    const role = response.data.user?.role
    const isAdmin = role === 'ADMIN' || role === 'COMMUNITY_ADMIN'
    const { res } = appContext.ctx

    if (route === '/admin/login' && isAdmin) {
      if (res !== undefined) {
        res.writeHead(307, { Location: '/admin' })
        res.end()
        return
      } else {
        Router.push('/admin').catch(console.error)
        return
      }
    } else if (route !== '/admin/login' && !isAdmin) {
      if (res !== undefined) {
        res.writeHead(307, { Location: '/admin/login' })
        res.end()
        return
      } else {
        Router.push('/admin/login').catch(console.error)
        return
      }
    }
  }

  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      userAgent
    }
  }
}

export default MyApp
