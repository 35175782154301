// https://github.com/vercel/next.js/blob/91146b23a21e33d54332a469f30afe6e6156cd65/examples/with-apollo/lib/apolloClient.js

import { useMemo } from 'react'
import {
  ApolloClient,
  FieldPolicy,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  Reference
} from '@apollo/client'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import { GraphQLError } from 'graphql'
import {
  RelayFieldPolicy,
  TExistingRelay,
  TRelayEdge,
  TRelayPageInfo
} from '@apollo/client/utilities/policies/pagination'
import { __rest } from 'tslib'
import { mergeDeep } from '@apollo/client/utilities'
import { initializeEndpoint } from '@/hooks/use-endpoint'
import possibleTypes from './possibleTypes.json'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCacheObject>

// https://github.com/apollographql/apollo-client/blob/v3.5.10/src/utilities/policies/pagination.ts#L91
// Returns any unrecognized properties of the given object.
const getExtras = (obj: Record<string, any>): any => __rest(obj, notExtras)
const notExtras = ['edges', 'pageInfo']
function makeEmptyData (): TExistingRelay<any> {
  return {
    edges: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      startCursor: '',
      endCursor: ''
    }
  }
}

type KeyArgs = FieldPolicy<any>['keyArgs']
function relayStylePagination2<TNode = Reference> (
  keyArgs: KeyArgs = false,
  argName?: string
): RelayFieldPolicy<TNode> {
  return {
    keyArgs,

    read (existing, { canRead, readField }) {
      if (existing === null || existing === undefined) return existing

      const edges: Array<TRelayEdge<TNode>> = []
      let firstEdgeCursor = ''
      let lastEdgeCursor = ''
      existing.edges.forEach(edge => {
        // Edges themselves could be Reference objects, so it's important
        // to use readField to access the edge.edge.node property.
        if (canRead(readField('node', edge))) {
          edges.push(edge)
          if (edge.cursor !== undefined) {
            firstEdgeCursor = firstEdgeCursor ?? edge.cursor ?? ''
            lastEdgeCursor = edge.cursor ?? lastEdgeCursor
          }
        }
      })

      const {
        startCursor,
        endCursor
      } = existing.pageInfo ?? {}

      return {
        // Some implementations return additional Connection fields, such
        // as existing.totalCount. These fields are saved by the merge
        // function, so the read function should also preserve them.
        ...getExtras(existing),
        edges,
        pageInfo: {
          ...existing.pageInfo,
          // If existing.pageInfo.{start,end}Cursor are undefined or "", default
          // to firstEdgeCursor and/or lastEdgeCursor.
          startCursor: startCursor ?? firstEdgeCursor,
          endCursor: endCursor ?? lastEdgeCursor
        }
      }
    },

    merge (existing, incoming, { args, isReference, readField }) {
      if (existing == null) {
        existing = makeEmptyData()
      }

      if (incoming == null) {
        return existing
      }

      const incomingEdges = (incoming.edges != null)
        ? incoming.edges.map(edge => {
          if (isReference(edge = { ...edge })) {
          // In case edge is a Reference, we read out its cursor field and
          // store it as an extra property of the Reference object.
            edge.cursor = readField<string>('cursor', edge)
          }
          return edge
        })
        : []

      if (incoming.pageInfo != null) {
        const { pageInfo } = incoming
        const { startCursor, endCursor } = pageInfo
        const firstEdge = incomingEdges[0]
        const lastEdge = incomingEdges[incomingEdges.length - 1]
        // In case we did not request the cursor field for edges in this
        // query, we can still infer cursors from pageInfo.
        if (firstEdge !== undefined && startCursor !== undefined) {
          firstEdge.cursor = startCursor
        }
        if (lastEdge !== undefined && endCursor !== undefined) {
          lastEdge.cursor = endCursor
        }
        // Cursors can also come from edges, so we default
        // pageInfo.{start,end}Cursor to {first,last}Edge.cursor.
        const firstCursor = firstEdge?.cursor
        if (firstCursor !== undefined && startCursor === undefined) {
          incoming = mergeDeep(incoming, {
            pageInfo: {
              startCursor: firstCursor
            }
          })
        }
        const lastCursor = lastEdge?.cursor
        if (lastCursor !== undefined && endCursor === undefined) {
          incoming = mergeDeep(incoming, {
            pageInfo: {
              endCursor: lastCursor
            }
          })
        }
      }

      let prefix = existing.edges
      let suffix: typeof prefix = []

      if (argName !== undefined && args != null) {
        args = args[argName]
      }
      if (args?.after !== undefined) {
        // This comparison does not need to use readField("cursor", edge),
        // because we stored the cursor field of any Reference edges as an
        // extra property of the Reference object.
        const index = prefix.findIndex(edge => edge.cursor === args?.after)
        if (index >= 0) {
          prefix = prefix.slice(0, index + 1)
          // suffix = []; // already true
        }
      } else if (args?.before !== undefined) {
        const index = prefix.findIndex(edge => edge.cursor === args?.before)
        suffix = index < 0 ? prefix : prefix.slice(index)
        prefix = []
      } else if (incoming.edges != null) {
        // If we have neither args.after nor args.before, the incoming
        // edges cannot be spliced into the existing edges, so they must
        // replace the existing edges. See #6592 for a motivating example.
        prefix = []
      }

      const edges = [
        ...prefix,
        ...incomingEdges,
        ...suffix
      ]

      const pageInfo: TRelayPageInfo = {
        // The ordering of these two ...spreads may be surprising, but it
        // makes sense because we want to combine PageInfo properties with a
        // preference for existing values, *unless* the existing values are
        // overridden by the logic below, which is permitted only when the
        // incoming page falls at the beginning or end of the data.
        ...incoming.pageInfo,
        ...existing.pageInfo
      }

      if (incoming.pageInfo != null) {
        const {
          hasPreviousPage, hasNextPage,
          startCursor, endCursor,
          ...extras
        } = incoming.pageInfo

        // If incoming.pageInfo had any extra non-standard properties,
        // assume they should take precedence over any existing properties
        // of the same name, regardless of where this page falls with
        // respect to the existing data.
        Object.assign(pageInfo, extras)

        // Keep existing.pageInfo.has{Previous,Next}Page unless the
        // placement of the incoming edges means incoming.hasPreviousPage
        // or incoming.hasNextPage should become the new values for those
        // properties in existing.pageInfo. Note that these updates are
        // only permitted when the beginning or end of the incoming page
        // coincides with the beginning or end of the existing data, as
        // determined using prefix.length and suffix.length.
        if (prefix.length === 0) {
          if (hasPreviousPage !== undefined) pageInfo.hasPreviousPage = hasPreviousPage
          if (startCursor !== undefined) pageInfo.startCursor = startCursor
        }
        if (suffix.length === 0) {
          if (hasNextPage !== undefined) pageInfo.hasNextPage = hasNextPage
          if (endCursor !== undefined) pageInfo.endCursor = endCursor
        }
      }

      return {
        ...getExtras(existing),
        ...getExtras(incoming),
        edges,
        pageInfo
      }
    }
  }
}

function createApolloClient (context?: {req?: {headers?: {cookie?: string|undefined}}}): ApolloClient<NormalizedCacheObject> {
  const { graphql } = initializeEndpoint()

  const ssrMode = typeof window === 'undefined'

  const enhancedFetch: WindowOrWorkerGlobalScope['fetch'] = async (url, init) =>
    await fetch(url, {
      ...init,
      headers: {
        ...init?.headers,
        Cookie: context?.req?.headers?.cookie ?? ''
      }
    })

  return new ApolloClient({
    connectToDevTools: true,
    ssrMode,
    link: new HttpLink({
      uri: graphql, // Server URL (must be absolute)
      credentials: 'include', // Additional fetch() options like `credentials` or `headers`
      fetch: ssrMode ? enhancedFetch : undefined
    }),
    cache: new InMemoryCache({
      possibleTypes,
      typePolicies: {
        Post: {
          fields: {
            posts: relayStylePagination2(false, 'pagination')
          }
        },
        PostOfPost: {
          fields: {
            posts: relayStylePagination2(false, 'pagination')
          }
        },
        User: {
          fields: {
            administeredCommunities: relayStylePagination2(false, 'pagination')
          }
        },
        Query: {
          fields: {
            users: relayStylePagination2(['filter'], 'pagination'),
            posts: relayStylePagination2(['filter'], 'pagination'),
            questions: relayStylePagination2(false, 'pagination'),
            communities: relayStylePagination2(['sort'], 'pagination'),
            reports: relayStylePagination2(['filter'], 'pagination'),
            notices: relayStylePagination2(false, 'pagination'),
            post (_, { args, toReference, canRead }) {
              if (args == null) {
                return undefined
              }
              // eslint-disable-next-line @typescript-eslint/naming-convention
              for (const __typename of possibleTypes.PostInterface) {
                const reference = toReference({
                  __typename,
                  id: args.id
                })
                if (canRead(reference)) {
                  return reference
                }
              }
            },
            question (_, { args, toReference, canRead }) {
              if (args == null) {
                return undefined
              }
              const reference = toReference({
                __typename: 'Question',
                id: args.id
              })
              if (canRead(reference)) {
                return reference
              }
            }
          }
        }
      }
    })
  })
}

export function initializeApollo (initialState = null, context?: {req?: {headers?: {cookie?: string|undefined}}}): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = apolloClient ?? createApolloClient(context)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState !== null) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        )
      ]
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (apolloClient === undefined) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState (client: ApolloClient<NormalizedCacheObject>, pageProps: any): any {
  if (pageProps?.props !== undefined) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo (pageProps: any): any {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  return useMemo(() => initializeApollo(state), [state])
}

interface GraphQLErrorResponse {
  graphQLErrors: GraphQLError[]
  // TODO: any
  clientErrors: any[]
  networkError: any
  message: string
  extraInfo: any
  stack: string
}
export function isGraphQLErrorResponse (error: any): error is GraphQLErrorResponse {
  return error.graphQLErrors !== undefined
}
